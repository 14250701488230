// This file has been generated by the Scope build. Do not alter
// it manually as your changes will be overwritten. Run:
// scope.cmd frontend:build-component-registry
// instead.

/* tslint:disable */

// prettier-ignore
import { setComponentRegistry, setThemeContextCreatorRegistry } from '@polarnopyret/scope/resolve-component';
// prettier-ignore
export { default as componentNames } from './component-names';

// prettier-ignore
const componentRegistry: {[name: string]: (theme: string) => Promise<{component: any, theme: string}>} = {
  'Avensia.Common.Features.Account.Login.LoginPageViewModel': (theme: string) => import('../Account/Login/LoginPage').then(m => ({component: m.default, theme: null})),
  'Avensia.Common.Features.Account.Register.RegisterPageViewModel': (theme: string) => import('../Account/Register/RegisterPage').then(m => ({component: m.default, theme: null})),
  'Avensia.Common.Features.AppShell.AppShellViewModel': (theme: string) => import('../AppShell/AppShell').then(m => ({component: m.default, theme: null})),
  'Avensia.Common.Features.Article.ArticlePageViewModel': (theme: string) => import('../Article/ArticlePage').then(m => ({component: m.default, theme: null})),
  'Avensia.Common.Features.Category.CategoryTreeViewModel': (theme: string) => import('../Category/Categories').then(m => ({component: m.default, theme: null})),
  'Avensia.Common.Features.Category.CategoryViewModel': (theme: string) => import('../Category/Category').then(m => ({component: m.default, theme: null})),
  'Avensia.Common.Features.Checkout.Pages.Checkout.CheckoutPageViewModel': (theme: string) => import('../Checkout/Pages/Checkout/CheckoutPage').then(m => ({component: m.default, theme: null})),
  'Avensia.Common.Features.Checkout.Pages.OrderConfirmation.OrderConfirmationPageViewModel': (theme: string) => import('../Checkout/Pages/OrderConfirmation/OrderConfirmationPage').then(m => ({component: m.default, theme: null})),
  'Avensia.Common.Features.Cms.CmsPageViewModel': (theme: string) => import('../Cms/CmsPage').then(m => ({component: m.default, theme: null})),
  'Avensia.Common.Features.CustomerService.CustomerServiceItemViewModel': (theme: string) => import('../CustomerService/CustomerServiceItemPage').then(m => ({component: m.default, theme: null})),
  'Avensia.Common.Features.CustomerService.CustomerServiceViewModel': (theme: string) => import('../CustomerService/CustomerServicePage').then(m => ({component: m.default, theme: null})),
  'Avensia.Common.Features.Error.Pages.PageNotFoundViewModel': (theme: string) => import('../Error/Pages/PageNotFound').then(m => ({component: m.default, theme: null})),
  'Avensia.Common.Features.GiftCards.GiftCardPageViewModel': (theme: string) => import('../GiftCards/GiftCardPage').then(m => ({component: m.default, theme: null})),
  'Avensia.Common.Features.MagazineStart.MagazineStartPageViewModel': (theme: string) => import('../MagazineStart/MagazineStartPage').then(m => ({component: m.default, theme: null})),
  'Avensia.Common.Features.Markets.MarketsPageViewModel': (theme: string) => import('../Markets/MarketsPage').then(m => ({component: m.default, theme: null})),
  'Avensia.Common.Features.MyPages.MyPagesInformationPageViewModel': (theme: string) => import('../MyPages/MyPagesInformationPage').then(m => ({component: m.default, theme: null})),
  'Avensia.Common.Features.MyPages.MyPagesProfilePageViewModel': (theme: string) => import('../MyPages/MyPagesProfilePage').then(m => ({component: m.default, theme: null})),
  'Avensia.Common.Features.PickUpInStore.ArrivingOrders.ArrivingOrdersPageViewModel': (theme: string) => import('../PickUpInStore/ArrivingOrders/ArrivingOrders').then(m => ({component: m.default, theme: null})),
  'Avensia.Common.Features.PickUpInStore.DeliveredOrders.DeliveredOrdersPageViewModel': (theme: string) => import('../PickUpInStore/DeliveredOrders/DeliveredOrders').then(m => ({component: m.default, theme: null})),
  'Avensia.Common.Features.PickUpInStore.StoreSelectionPageViewModel': (theme: string) => import('../PickUpInStore/StoreSelectionPage').then(m => ({component: m.default, theme: null})),
  'Avensia.Common.Features.Product.VariationViewModel': (theme: string) => import('../Product/Variation').then(m => ({component: m.default, theme: null})),
  'Avensia.Common.Features.ResetPassword.ResetPasswordPageViewModel': (theme: string) => import('../ResetPassword/ResetPasswordPage').then(m => ({component: m.default, theme: null})),
  'Avensia.Common.Features.Search.SearchPageViewModel': (theme: string) => import('../Search/SearchPage').then(m => ({component: m.default, theme: null})),
  'Avensia.Common.Features.Shared.Blocks.AccordionBlock.AccordionBlockViewModel': (theme: string) => import('./Blocks/AccordionBlock/AccordionBlock').then(m => ({component: m.default, theme: null})),
  'Avensia.Common.Features.Shared.Blocks.BlockSlider.BlockSliderBlockViewModel': (theme: string) => import('./Blocks/BlockSlider/BlockSliderBlock').then(m => ({component: m.default, theme: null})),
  'Avensia.Common.Features.Shared.Blocks.CountryLink.CountryLinkBlockViewModel': (theme: string) => import('./Blocks/CountryLink/CountryLinkBlock').then(m => ({component: m.default, theme: null})),
  'Avensia.Common.Features.Shared.Blocks.CTABlock.CTABlockViewModel': (theme: string) => import('./Blocks/CTABlock').then(m => ({component: m.default, theme: null})),
  'Avensia.Common.Features.Shared.Blocks.Editorial.EditorialBlock': (theme: string) => import('./Blocks/Editorial/EditorialBlock').then(m => ({component: m.default, theme: null})),
  'Avensia.Common.Features.Shared.Blocks.Hero.HeroBlockViewModel': (theme: string) => import('./Blocks/Hero/HeroBlock').then(m => ({component: m.default, theme: null})),
  'Avensia.Common.Features.Shared.Blocks.Html.CssAndScriptBlock': (theme: string) => import('./Blocks/Html/CssAndScriptBlock').then(m => ({component: m.default, theme: null})),
  'Avensia.Common.Features.Shared.Blocks.Image.ImageBlockViewModel': (theme: string) => import('./Blocks/Image/ImageBlock').then(m => ({component: m.default, theme: null})),
  'Avensia.Common.Features.Shared.Blocks.Imbox.ImboxBlockViewModel': (theme: string) => import('./Blocks/Imbox/CustomerService').then(m => ({component: m.default, theme: null})),
  'Avensia.Common.Features.Shared.Blocks.InformationBlock.InformationBlockViewModel': (theme: string) => import('./Blocks/InformationBlock/InformationBlock').then(m => ({component: m.default, theme: null})),
  'Avensia.Common.Features.Shared.Blocks.Magazine.MagazineArticleBlockViewModel': (theme: string) => import('./Blocks/Magazine/MagazineArticleBlock').then(m => ({component: m.default, theme: null})),
  'Avensia.Common.Features.Shared.Blocks.Magazine.MagazineBlockViewModel': (theme: string) => import('./Blocks/Magazine/MagazineBlock').then(m => ({component: m.default, theme: null})),
  'Avensia.Common.Features.Shared.Blocks.Product.ProductBlockViewModel': (theme: string) => import('./Blocks/Product/ProductBlock').then(m => ({component: m.default, theme: null})),
  'Avensia.Common.Features.Shared.Blocks.Product.ProductGroupBlockViewModel': (theme: string) => import('./Blocks/Product/ProductGroup').then(m => ({component: m.default, theme: null})),
  'Avensia.Common.Features.Shared.Blocks.Product.ProductGroupItemBlockViewModel': (theme: string) => import('./Blocks/Product/ProductGroupItemBlock').then(m => ({component: m.default, theme: null})),
  'Avensia.Common.Features.Shared.Blocks.Product.ProductListBlockViewModel': (theme: string) => import('./Blocks/Product/ProductListBlock').then(m => ({component: m.default, theme: null})),
  'Avensia.Common.Features.Shared.Blocks.ProductFeature.ProductFeatureBlockViewModel': (theme: string) => import('./Blocks/ProductFeature/ProductFeatureBlock').then(m => ({component: m.default, theme: null})),
  'Avensia.Common.Features.Shared.Blocks.ProductFeature.ProductFeatureListBlockViewModel': (theme: string) => import('./Blocks/ProductFeature/ProductFeatureListBlock').then(m => ({component: m.default, theme: null})),
  'Avensia.Common.Features.Shared.Blocks.RowLayout.RowLayoutBlockViewModel': (theme: string) => import('./Blocks/RowLayout/RowLayoutBlock').then(m => ({component: m.default, theme: null})),
  'Avensia.Common.Features.Shared.Blocks.SaleBlock.SaleBlockViewModel': (theme: string) => import('./Blocks/SaleBlock/SaleBlock').then(m => ({component: m.default, theme: null})),
  'Avensia.Common.Features.Shared.Blocks.ShopTheLook.ShopTheLookBlockViewModel': (theme: string) => import('./Blocks/ShopTheLook/ShopTheLookBlock').then(m => ({component: m.default, theme: null})),
  'Avensia.Common.Features.Shared.Blocks.ShopTheLook.ShopTheLookGroupBlockViewModel': (theme: string) => import('./Blocks/ShopTheLook/ShopTheLookGroupBlock').then(m => ({component: m.default, theme: null})),
  'Avensia.Common.Features.Shared.Blocks.ShopTheLook.ShopTheLookItemBlockViewModel': (theme: string) => import('./Blocks/ShopTheLook/ShopTheLookItemBlock').then(m => ({component: m.default, theme: null})),
  'Avensia.Common.Features.Shared.Blocks.TextBlock.SliderTextBlockViewModel': (theme: string) => import('./Blocks/TextBlock/SliderTextBlock').then(m => ({component: m.default, theme: null})),
  'Avensia.Common.Features.Shared.Blocks.TextBlock.TextBlockViewModel': (theme: string) => import('./Blocks/TextBlock/TextBlock').then(m => ({component: m.default, theme: null})),
  'Avensia.Common.Features.Shared.Blocks.Video.VideoProductsBlockViewModel': (theme: string) => import('./Blocks/Video/VideoProductsBlock').then(m => ({component: m.default, theme: null})),
  'Avensia.Common.Features.Shared.Partials.PreviewViewModel': (theme: string) => import('./Partials/Preview').then(m => ({component: m.default, theme: null})),
  'EPiServer.Core.XhtmlString': (theme: string) => import('./Partials/PropertyTypes/Html').then(m => ({component: m.default, theme: null})),
  'Avensia.Common.Features.SiteLayout.Footer.SiteFooter': (theme: string) => import('../SiteLayout/Footer').then(m => ({component: m.default, theme: null})),
  'Avensia.Common.Features.SiteLayout.Footer.OptionLinks.MultipleOptionLinksBlockViewModel': (theme: string) => import('../SiteLayout/Footer/OptionLinks/MultipleOptionLinksBlock').then(m => ({component: m.default, theme: null})),
  'Avensia.Common.Features.SiteLayout.Footer.OptionLinks.SingleOptionLinksBlockViewModel': (theme: string) => import('../SiteLayout/Footer/OptionLinks/SingleOptionLinksBlock').then(m => ({component: m.default, theme: null})),
  'Avensia.Common.Features.SizeGuide.SizeGuideBlock': (theme: string) => import('../SizeGuide/SizeGuideBlock').then(m => ({component: m.default, theme: null})),
  'Avensia.Common.Features.Start.StartPageViewModel': (theme: string) => import('../Start/StartPage').then(m => ({component: m.default, theme: null})),
  'Avensia.Common.Features.Stores.DeviatingOpeningHourBlock': (theme: string) => import('../Stores/DeviatingOpeningHourBlock').then(m => ({component: m.default, theme: null})),
  'Avensia.Common.Features.Stores.StoreLandingPageViewModel': (theme: string) => import('../Stores/StoreLandingPage').then(m => ({component: m.default, theme: null})),
  'Avensia.Common.Features.WishList.WishListPageViewModel': (theme: string) => import('../WishList/WishList').then(m => ({component: m.default, theme: null})),
  'EPiServer.Core.ContentArea': (theme: string) => import('@polarnopyret/scope-episerver/PropertyTypes/ContentArea').then(m => ({component: m.default, theme: null})),
  'EPiServer.SpecializedProperties.LinkItemCollection': (theme: string) => import('@polarnopyret/scope-episerver/PropertyTypes/LinkItemCollection').then(m => ({component: m.default, theme: null})),
};

// prettier-ignore
setComponentRegistry(componentRegistry);
// prettier-ignore
export default componentRegistry;

// prettier-ignore
const themeContextCreatorRegistry = {};
// prettier-ignore
setThemeContextCreatorRegistry(themeContextCreatorRegistry);
