import Market from 'AppShell/Models/Market.type';
import React, { useState } from 'react';
import connect from 'Shared/connect';
import DropdownBody from 'Shared/Dropdown/DropdownBody';
import DropdownShell from 'Shared/Dropdown/DropdownShell';
import * as style from 'Shared/Style';
import { ButtonHeight, NonInteractiveSolidButton } from 'Shared/TextButtons';
import currentPageIsCheckout from '../Checkout/Pages/Checkout/current-page-is-checkout';

import { styled, StyledProps } from '@glitz/react';

import SizeViewModel from './SizeViewModel.type';
import { isCompact } from 'Shared/Viewport';
import SizeBaseViewModel from './SizeBaseViewModel.type';
import { PageType } from 'Shared/State';

const SizeTagsWrap = styled.div({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  gap: '8px',
  flexWrap: 'wrap',
});

const StyledDropdownShell = styled(DropdownShell, {
  width: '100%',
  maxWidth: '500px',
});

const SizeTagsContainer = styled(({ children }: { children: React.ReactNode } & StyledProps) => {
  return (
    <SizeTagsWrap>
      {children}
    </SizeTagsWrap>
  );
});

type SelectableSizesType = {
  sizes: Array<SizeViewModel | SizeBaseViewModel>;
  selectedSizeCode: string;
  setSelectedSize: (size: SizeViewModel | SizeBaseViewModel, name?: string) => void;
  placeholder: string;
  market: Market;
  currentBreakpoint: number;
  currentPage: PageType;
};

const MARKETS_USING_AGE_AS_SIZE = ['GB', 'DE'];
const USE_SIZE_AS_DISPLAY_NAME = true;

const SelectableSizesInternal = function ({
  sizes,
  selectedSizeCode,
  setSelectedSize,
  placeholder,
  market,
  currentBreakpoint,
  currentPage
}: SelectableSizesType) {
  const [displayName, setDisplayName] = useState<string>(() => {
    if (!USE_SIZE_AS_DISPLAY_NAME) return placeholder;

    const size = sizes.find((size) => size.code === selectedSizeCode);
    if (!size) return placeholder;

    return getSizeDisplayName(size);
  });

  function isAgeAsSizePrimary(market: Market) {
    return MARKETS_USING_AGE_AS_SIZE.includes(market.id);
  }

  function getSizeDisplayName(size: SizeViewModel | SizeBaseViewModel) {

    if (!size.sizeAge || size.sizeAge === '') {
      return size.sizeName;
    }

    const primarySize = isAgeAsSizePrimary(market) ? size.sizeAge : size.sizeName;
    const secondarySize = isAgeAsSizePrimary(market) ? size.sizeName : size.sizeAge;
    return `${primarySize} (${secondarySize})`;
  }

  function onSizeChange(sizeCode: string) {
    const newSize = sizes.find((size) => size.code === sizeCode);
    if (newSize) {
      setSelectedSize(newSize, getSizeDisplayName(newSize));
      if (USE_SIZE_AS_DISPLAY_NAME) setDisplayName(getSizeDisplayName(newSize));
    }
  }

  const isMobile = isCompact(currentBreakpoint);
  const options = sizes.map((size) => {
    const primarySize = size.sizeAge && size.sizeAge !== '' && isAgeAsSizePrimary(market) ? size.sizeAge : size.sizeName;
    const secondarySize = isAgeAsSizePrimary(market) ? size.sizeName : size.sizeAge;

    let isDisabled = false;
    if ('inStock' in size) isDisabled = !(size as SizeViewModel).inStock;
    else if ('stockAvailability' in size) isDisabled = (size as SizeViewModel).stockAvailability === 'none';
    else isDisabled = size.centralStockAvailability === 0;

    return {
      text: (
        <>
          <b>{primarySize}</b> {secondarySize && secondarySize !== '' && `(${secondarySize})`}
        </>
      ),
      /* TODO Update text to be localized
      secondaryText:
        size.centralStockAvailability === 0
          ? 'Out of stock'
          : size.centralStockAvailability <= 10
          ? `Only ${size.centralStockAvailability} left in stock`
          : undefined,*/
      value: size.code,
      disabled: isDisabled,
    };
  });

  return (
    <SizeTagsContainer>
      <StyledDropdownShell
        css={{
          border: {
            radius: '16px',
          },
        }}
        displayNameStyle={{
          padding: {
            x: '16px',
          },
        }}
        displayName={displayName}
        closeOnClickInContent={!isMobile}
        noTopMargin
        noOpenBorderBottom
        useModal={isMobile}
        borderWidth="medium"
        borderRadius="8px"
        withPortal={currentPageIsCheckout(currentPage)}
      >
        <DropdownBody
          ulStyle={{
            backgroundColor: style.colors.monochrome.extraLightGrey,
            gap: '8px',
            borderTop: {
              x: {
                radius: 0,
              },
            },
            overflowY: 'auto',
            ...(isMobile
              ? {
                border: {
                  xy: {
                    width: 0,
                  },
                },
                maxHeight: '400px',
              }
              : {
                border: {
                  xy: {
                    width: 'medium',
                  },
                  top: {
                    width: '0',
                  },
                  radius: '8px',
                },
                maxHeight: '310px',
              }),
          }}
          liStyle={{
            backgroundColor: style.colors.monochrome.white,
            padding: {
              y: '24px',
              x: '12px',
            },
          }}
          textStyle={{
            padding: {
              x: '16px',
            },
          }}
          hideCount
          options={options}
          onChangeOption={onSizeChange}
          value={selectedSizeCode}
        />
      </StyledDropdownShell>
    </SizeTagsContainer >
  );
};

export const SelectableSizes = connect((state) => ({
  market: state.appShellData.market,
  currentBreakpoint: state.currentBreakpoint,
  currentPage: state.currentPage,
}))(SelectableSizesInternal);

type AvailableSizesType = {
  availableSizes: { [item: string]: boolean };
};

export const AvailableSizes = function ({ availableSizes }: AvailableSizesType) {
  return (
    <SizeTagsContainer>
      {Object.entries(availableSizes).map((entry, index) => {
        const [size, isAvailable] = entry;
        return (
          <NonInteractiveSolidButton
            key={index}
            text={size}
            height={ButtonHeight.Small}
            disabled={!isAvailable}
            padding={{ x: 12 }}
            fontSize={12}
          />
        );
      })}
    </SizeTagsContainer>
  );
};
