import React from 'react';
import { styled, StyledProps } from '@glitz/react';
import { MainMenuType } from 'Shared/State';
import SitePagesType from '../../../AppShell/Models/SitePages.type';
import MenuBar from '../MenuBar';
import ActionButtons from '../ActionButtons';
import * as style from 'Shared/Style';
import Link from 'Shared/Link';
import { AltLogo } from 'Shared/Logotype';
import { Usp } from 'SiteLayout/Header/USP';
import connect from 'Shared/connect';
import { QuickSearchInputForm } from 'Search/QuickSearch/QuickSearchForm';
import currentPageIsVariation from 'Product/current-page-is-variation';
import currentPageIsGiftcardpage from 'GiftCards/current-page-is-giftcardpage';
import currentPageIsOrderConfirmation from 'Checkout/Pages/OrderConfirmation/current-page-is-order-confirmation';
import { SubMenu } from './SubMenu';
import StoreBanner from './../../StoreBanner';

export const ANIMATION_DURATION = 250;
export const HEIGHT = 92;
export const COMPACT_HEIGHT = 48;
export const TOP_ROW_HEIGHT = COMPACT_HEIGHT;
export const HR_HEIGHT = 1; //Doubled; applies to top and bottom
export const BOTTOM_ROW_HEIGHT = HEIGHT - (TOP_ROW_HEIGHT + HR_HEIGHT * 2);

type ConnectedPropType = {
  isProductPage: boolean;
  isGiftCardPage: boolean;
  isOrderConfirmationPage: boolean;
};

type PropType = ConnectedPropType & StyledProps & {
  currentBreakpoint: number;
  mainMenu: MainMenuType;
  quickSearchIsOpen: boolean;
  pages: SitePagesType;
  isCollapsed: boolean;
  showQuickSearch: () => void;
  showScrollUpHeader: () => void;
  transparentAtTop: boolean;
  isScrolled: boolean;
  isHovered: boolean;
  showTopLinks: boolean;
  toggleTopLinks: () => void;
  isStoreMode: boolean;
};

const ConditionalTop = styled.div({
  height: '32px',
  position: 'absolute',
  width: '100%',
  top: '-32px',
  overflow: 'hidden',
})

const ConditionalTopInner = styled.div({
  height: '64px',
  position: 'absolute',
  width: '100%',
  ...style.transition({ property: ['top'], duration: `${ANIMATION_DURATION}ms` }),
})

const TopRow = styled.div({
  position: 'relative',
  display: 'grid',
  gridTemplateColumns: '1fr 100px 1fr',
  pointerEvents: 'auto',
  zIndex: 1,
  ...style.transition({ property: ['color'], duration: `${ANIMATION_DURATION}ms` }),
  height: TOP_ROW_HEIGHT,
});

const LogoWrap = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
})

const BottomRow = styled.div({
  position: 'relative',
  display: 'flex',
  width: '100%',
  height: `${BOTTOM_ROW_HEIGHT}px`,
  alignItems: 'center',
  pointerEvents: 'auto',
  opacity: 1,
  transform: 'translateY(0)',
  paddingTop: HEIGHT - COMPACT_HEIGHT - HR_HEIGHT,
  clipPath: 'inset(100% 0% 0% 0%)',
  ...style.transition({ property: ['color', 'clip-path'], duration: `${ANIMATION_DURATION}ms` }),
});

const VisualBackground = styled.div({
  // We simulate a header height change here by moving this empty element up. This is much more performant.
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  height: HEIGHT + 'px',
  ...style.transition({ property: ['transform', 'background-color'], duration: `${ANIMATION_DURATION}ms` }),
});

const HomeLink = styled(Link, {
  display: 'block',
  height: '28px',
});

const TopLogo = styled(AltLogo, {
  width: 'auto',
  height: '28px',
});

const Wrap = styled.div({
  width: `calc(100% - ${16 * 2}px)`,
  margin: { x: 'auto' }
})

const Line = styled.hr({
  position: 'relative',
  height: '2px',
  border: { y: { width: 0 }, x: { width: 0 } },
  ...style.transition({ property: ['margin', 'background-color'], duration: `${ANIMATION_DURATION}ms` }),
})

const DesktopHeader = styled((props: PropType) => {
  const onHeaderClick = (e: React.SyntheticEvent<{}>) => {
    if (props.isCollapsed) {
      props.showScrollUpHeader();
    }
  };

  const {
    isCollapsed,
    isScrolled,
    transparentAtTop,
    isHovered,
    toggleTopLinks,
    showTopLinks,
    quickSearchIsOpen,
    isProductPage,
    isGiftCardPage,
    isOrderConfirmationPage,
    isStoreMode,
  } = props;

  const treatAsCollapsed = !(!isCollapsed || showTopLinks) && !quickSearchIsOpen;
  const baseBackground =
    !isScrolled && transparentAtTop && !((isHovered && isScrolled) || quickSearchIsOpen) ? 'transparent' : 'white';

  const fgColors = [style.colors.monochrome.white, style.colors.monochrome.black];
  const fgCondition =
    !isScrolled &&
    transparentAtTop &&
    !((isHovered && isScrolled) || quickSearchIsOpen) &&
    !(isProductPage || isGiftCardPage || isOrderConfirmationPage);
  const foregroundColor = fgCondition ? fgColors[0] : fgColors[1];
  const inputPlaceHolderFocusColor = fgCondition ? fgColors[0] : style.colors.monochrome.a11ygrey;

  return (
    <div onClick={onHeaderClick}>
      <ConditionalTop>
        <ConditionalTopInner css={{ top: showTopLinks ? '-32px' : '0px', }} >
          {isStoreMode && <StoreBanner />}
          {!isStoreMode && <Usp delay={5000} isMobile={false} />}
          <SubMenu toggleTopLinks={toggleTopLinks} />
        </ConditionalTopInner>
      </ConditionalTop>
      <VisualBackground
        css={{
          backgroundColor: baseBackground,
          transform: treatAsCollapsed && !isHovered ? `translateY(-${HEIGHT - COMPACT_HEIGHT}px)` : 'translateY(0)',
        }}
      />
      <Wrap>
        <TopRow css={{ color: foregroundColor, }}>
          <QuickSearchInputForm
            placeholderColor={foregroundColor}
            inputPlaceHolderFocusColor={inputPlaceHolderFocusColor}
            isMobile={false}
          />
          <LogoWrap>
            <HomeLink to={props.pages.startPage}>
              <TopLogo css={{ color: foregroundColor }} />
            </HomeLink>
          </LogoWrap>
          <ActionButtons withText={true} inverseFGColor={fgCondition} toggleTopLinks={toggleTopLinks} />
        </TopRow>
        <Line
          css={{
            backgroundColor: foregroundColor,
            margin: { x: treatAsCollapsed && !isHovered ? -16 : 0, y: 0 },
          }}
        />
        <BottomRow
          css={{
            color: foregroundColor,
            ...((treatAsCollapsed && !isHovered) && {
              pointerEvents: 'none',
              opacity: 0,
              transform: 'translateY(-34px)',
            }),
            ...((!treatAsCollapsed || isHovered) && {
              paddingTop: 0,
              clipPath: 'inset(0% 0% 0% 0%)',
            }),
          }}
        >
          <MenuBar isVisible={!treatAsCollapsed} mainMenu={props.mainMenu} foreGroundColor={foregroundColor} />
        </BottomRow>
      </Wrap>
    </div>
  );
});

export default connect(
  (state) => ({
    isProductPage: currentPageIsVariation(state.currentPage),
    isGiftCardPage: currentPageIsGiftcardpage(state.currentPage),
    isOrderConfirmationPage: currentPageIsOrderConfirmation(state.currentPage)
  }),
  (dispatch) => ({}),
)(DesktopHeader);
