import { styled } from '@glitz/react';
import * as style from 'Shared/Style';

export const OptionLi = styled.li({
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  width: '100%',
  height: `calc(1.5em + 4 * 2)`,
  lineHeight: '1.2',
  paddingTop: '8px',
  paddingBottom: '8px',
  paddingRight: '32px',
  paddingLeft: '8px',
  ...style.transition({
    property: 'background-color',
    duration: '0.3s',
    timingFunction: 'ease',
  }),
  ':hover': {
    backgroundColor: style.colors.monochrome.lightGrey,
    cursor: 'pointer',
  },
  ...style.truncate(),
});

export const HeadText = styled.span({
  display: 'block',
  flexGrow: 1,
  flexShrink: 1,
  flexBasis: 'auto',
});
