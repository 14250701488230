import { styled } from '@glitz/react';
import React from 'react';
import Link from 'Shared/Link';
import * as style from 'Shared/Style';

type SpecialOfferProps = {
  offerName: string;
  offerLinkText: string;
  offerUrl: string;
};

const StyledDiv = styled.div({
  backgroundColor: style.colors.functionColors.specialOffer,
  borderRadius: '8px',
  display: 'flex',
  flexDirection: 'column',
  padding: {
    x: '16px',
    y: '8px'
  },
  ['@media ' + style.mediaMinLarge]: {
    borderRadius: 'calc(infinity * 1px)',
    textAlign: 'center',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  columnGap: '4px',
  fontSize: '16px',
});

const StyledParagraph = styled.p({
  margin: {
    xy: 0,
  },
});

export function SpecialOffer({ offerName, offerLinkText, offerUrl }: SpecialOfferProps) {
  return (
    <StyledDiv>
      <StyledParagraph>
        <b>{offerName}</b>
      </StyledParagraph>{' '}
      <Link
        to={offerUrl}
        css={{
          color: style.colors.monochrome.black,
          textDecoration: 'underline',
          fontSize: '16px',
          fontWeight: 450,
        }}
      >
        {offerLinkText}
      </Link>
    </StyledDiv>
  );
}
