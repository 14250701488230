import React from 'react';
import Ul from 'Shared/Generic/Ul';
import { Check12x12 } from 'Shared/Icons/Check';
import { ResponsiveSize, Text } from 'Shared/SharedComponents/atoms/Typography';
import * as style from 'Shared/Style';

import { styled } from '@glitz/react';
import { StyleOrStyleArray } from '@glitz/type';

import { OptionLi } from './parts';

const OptionUl = styled(Ul, {
  border: {
    xy: {
      width: 'thin',
      style: 'solid',
      color: style.colors.monochrome.darkGrey,
    },
  },
  backgroundColor: style.colors.monochrome.white,
  display: 'flex',
  flexDirection: 'column',
});

const CheckMark = styled(Check12x12, {
  position: 'absolute',
  fontSize: '16px',
  top: `calc((1.5em / 2) - (4 / 2))`,
  right: '8px',
});

const SelectionWrapper = styled.span({
  color: style.colors.monochrome.darkestGrey,
  marginLeft: '4px',
});

const OptionText = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  paddingLeft: '48px',
  paddingRight: '32px',
  ['@media ' + style.mediaMinLarge]: {
    paddingLeft: 0,
    paddingRight: '64px',
  },
});

export type CustomSortType = {
  text: React.ReactNode | string;
  secondaryText?: React.ReactNode | string;
  value: string;
  count?: number;
  fieldName?: string;
  image?: string;
  disabled?: boolean;
};

type CustomSelectType = {
  options: CustomSortType[];
  value: string;
};

type PropType = React.HTMLAttributes<HTMLInputElement> &
  CustomSelectType & {
    values?: string[];
    onChangeOption?: (value: string) => any;
    hideCount?: boolean;
    markSelected?: boolean;
    liStyle?: StyleOrStyleArray;
    ulStyle?: StyleOrStyleArray;
    textStyle?: StyleOrStyleArray;
  };

export default class DropdownBody extends React.Component<PropType> {
  render() {
    const selected = this.props.options.find((o) => o.value === this.props.value);

    return (
      <OptionUl column css={this.props.ulStyle}>
        {this.props.options.map((option) => (
          <OptionLi
            css={{
              pointerEvents: option.disabled ? 'none' : undefined,
              ...this.props.liStyle,
            }}
            key={option.value}
            onClick={() => !option.disabled && this.props.onChangeOption(option.value)}
          >
            <styled.Div css={{ width: '100%' }}>
              <OptionText>
                <Text
                  fontSize={ResponsiveSize.D14_M14}
                  css={{
                    color: option.disabled ? style.colors.monochrome.darkGrey : undefined,
                    ...this.props.textStyle,
                  }}
                >
                  {option.text}
                </Text>
                <Text fontSize={ResponsiveSize.D12_M12}>{option.secondaryText}</Text>
              </OptionText>
              {!this.props.hideCount && <SelectionWrapper>({option.count})</SelectionWrapper>}
            </styled.Div>
            {this.props.markSelected && (this.props.values
              ? this.props.values.indexOf(option.value) > -1 && <CheckMark />
              : selected && selected.text === option.text && <CheckMark />)}
          </OptionLi>
        ))}
      </OptionUl>
    );
  }
}
