import React from 'react';
import { styled, StyledProps } from '@glitz/react';
import { RatioPicture, Ratio as ImageRatio, Preset } from 'Shared/Image/Ratio';
import Price from 'Pricing/Price';
import { ErrorMessage } from 'Shared/FeedbackMessage';
import CartItemType from 'Cart/Models/CartItemViewModel.type';
import CheckoutLabels from 'Checkout/Pages/Checkout/CheckoutPageViewModel+CheckoutLabels.type';
import BackupStore from 'Checkout/Pages/Checkout/BackupStoreModel.type';
import CartLabels from 'Cart/Models/MiniCartViewModel+CartLabels.type';
import IncompleteOfferViewModel from 'Cart/Models/IncompleteOfferViewModel.type';
import * as style from 'Shared/Style';
import Link from 'Shared/Generic/A';
import QuantityField from 'Shared/Quantity';
import { Text, TextBlock, ResponsiveSize } from 'Shared/SharedComponents/atoms/Typography';
import { colorFromTag } from 'Shared/utils';
import { Trash12x12 } from 'Shared/Icons/Trash';
import { replaceState } from '@polarnopyret/scope';
import { SpecialOffer } from './SpecialOffer';

type PropType = StyledProps & {
  item: CartItemType;
  labels: CheckoutLabels | CartLabels;
  backupStore?: BackupStore;
  incompleteOffer?: IncompleteOfferViewModel;
  isMobile: boolean;
  update: (lineId: number, code: string, quantity: number, previousQuantity: number) => Promise<void>;
  remove: (lineId: number, code: string, previousQuantity: number) => Promise<void>;
  close?: () => void;
};

const Base = styled.div({
  display: 'flex',
  backgroundColor: style.colors.monochrome.extraLightGrey,
  padding: {
    xy: 8,
  },
  borderRadius: 8,
  marginBottom: 12,
});

const Media = styled.div({
  flexBasis: '98px',
});

const ContentBase = styled.div({
  position: 'relative',
  fontSize: 14,
  flexGrow: 1,
  display: 'flex',
});

const ContentMobile = styled(ContentBase, {
  flexDirection: 'column',
  gap: '6px',
  marginLeft: '8px',
});

const ContentDesktop = styled(ContentBase, {
  margin: { xy: 8 },
});

const SizeData = styled(TextBlock, {
  color: style.colors.monochrome.a11ygrey,
});

const CartItemLink = styled(Link, {
  display: 'block',
  textTransform: 'uppercase',
  fontWeight: 500,
  color: style.colors.monochrome.black,
  fontSize: '14px',
  lineHeight: '100%',
});

const ItemPrice = styled(Price, {});

const GiftCardEditingContainer = styled.div({
  textAlign: 'right',
});

const Message = styled(ErrorMessage, {
  height: `calc(16px * 1.5)`,
});

const MobileQuantityField = styled(QuantityField, {
  marginTop: '8px',
  display: 'flex',
});

const RemoveIconWrap = styled.span({
  textTransform: 'uppercase',
  fontWeight: 500,
  cursor: 'pointer',
  color: style.colors.monochrome.a11ygrey,
  ['@media ' + style.mediaUpToLarge]: {
    display: 'block',
    height: '12px',
    textAlign: 'right',
  },
});

const RemoveIcon = styled(Trash12x12, {
  marginLeft: '12px',
  ['@media ' + style.mediaMinLarge]: {
    marginTop: '3px',
  },
});

const TagsAndPrice = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'end',
  gap: '14px',
});

const Tags = styled.div({
  display: 'flex',
  flexDirection: 'row',
});

const DesktopTagsText = styled(Text, {
  marginRight: '12px',
});

const DesktopEditAndQuantity = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'end',
});

const DesktopEditAndQuantityInner = styled.div({
  display: 'flex',
  alignSelf: 'end',
});

const GiftCardEditLink = styled(Link, {
  textTransform: 'uppercase',
  fontWeight: 500,
  cursor: 'pointer',
  color: style.colors.monochrome.a11ygrey,
});

const ItemDataContent = styled.div({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  gap: '4px',
});

const ItemDataDeskTopWrap = styled.div({
  display: 'flex',
  flexGrow: 1,
  flexDirection: 'column',
  gap: '14px',
});

const ItemButtonsDesktopWrap = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  textAlign: 'right',
});

const BackupStoreNote = styled(TextBlock, {
  color: style.colors.brandColors.yellow,
});

const Item = (props: PropType) => {
  const {
    item: { totalPlacedPrice, totalListPrice, totalComparePrice, type, giftCardInformation },
    isMobile,
  } = props;

  const giftCard = type === 'giftCard' && giftCardInformation;
  const ContentWrap = isMobile ? ContentMobile : ContentDesktop;

  const ItemData = (
    <>
      <ItemDataContent>
        <CartItemLink
          to={props.item.url}
          onClick={() => {
            if (props.close) {
              props.close();
            }
          }}
        >
          {props.item.displayName}
        </CartItemLink>
        {giftCard ? (
          <>
            <TextBlock fontSize={ResponsiveSize.D14_M14_L130}>{giftCard.date}</TextBlock>
            <TextBlock fontSize={ResponsiveSize.D14_M14_L130}>{giftCard.deliveryType}</TextBlock>
            <TextBlock fontSize={ResponsiveSize.D14_M14_L130}>{giftCard.phoneOrEmail}</TextBlock>
          </>
        ) : (
          <>
            <TextBlock fontSize={ResponsiveSize.D14_M14_L130}>{props.item.oneLineText}</TextBlock>
            <SizeData fontSize={ResponsiveSize.D14_M14_L130}>
              {props.item.color}, {props.labels.size}: {props.item.sizeAndAge}
            </SizeData>
            {props.backupStore?.enabled && props.item.isSentFromBackupStore && (
              <BackupStoreNote>{props.backupStore?.backupInventoryLineItemMessage}</BackupStoreNote>
            )}
          </>
        )}
      </ItemDataContent>
      <TagsAndPrice>
        <Tags>
          {props.item.tags &&
            props.item.tags.slice(0, 2).map((tag, index) => (
              <DesktopTagsText
                upperCase
                fontSize={ResponsiveSize.D12_M12}
                key={index}
                css={{ color: colorFromTag(tag.code, tag.color) }}
              >
                {tag.tag}
              </DesktopTagsText>
            ))}
        </Tags>
        <ItemPrice
          sellingPrice={totalPlacedPrice}
          listPrice={totalListPrice}
          comparePrice={totalComparePrice}
          fontSize={14}
          fontSizePrevPrice={12}
          displayPriceLabels={true}
        />
      </TagsAndPrice>
    </>
  );

  return (
    <styled.Div css={props.compose()}>
      <Base>
        <Media>
          <CartItemLink
            to={props.item.url}
            onClick={() => {
              if (props.close) {
                props.close();
              }
            }}
          >
            <RatioPicture
              src={props.item.imageUrl}
              alt={props.item.displayName}
              ratio={ImageRatio.ThreeToFour}
              preset={Preset.ItemThumbLarge}
              borderRadius={8}
            />
          </CartItemLink>
          {isMobile && !giftCard && (
            <MobileQuantityField
              value={props.item.quantity}
              update={(quantity) => props.update(props.item.lineItemId, props.item.code, quantity, props.item.quantity)}
            />
          )}
        </Media>
        <ContentWrap>
          {!isMobile && (
            <>
              <ItemDataDeskTopWrap>{ItemData}</ItemDataDeskTopWrap>
              <ItemButtonsDesktopWrap>
                <div>
                  <RemoveIconWrap
                    onClick={() => props.remove(props.item.lineItemId, props.item.code, props.item.quantity)}
                  >
                    {!props.isMobile && props.labels.remove}
                    <RemoveIcon />
                  </RemoveIconWrap>
                </div>
                <DesktopEditAndQuantity>
                  <DesktopEditAndQuantityInner>
                    {giftCard && (
                      <GiftCardEditingContainer>
                        <GiftCardEditLink
                          to={giftCard.updateUrl}
                          onClick={(e) => {
                            e.preventDefault();
                            replaceState(giftCard.updateUrl);
                            if (props.close) {
                              props.close();
                            }
                          }}
                        >
                          {props.labels.edit}
                        </GiftCardEditLink>
                      </GiftCardEditingContainer>
                    )}

                    {!giftCard && (
                      <QuantityField
                        value={props.item.quantity}
                        update={(quantity) =>
                          props.update(props.item.lineItemId, props.item.code, quantity, props.item.quantity)
                        }
                      />
                    )}
                  </DesktopEditAndQuantityInner>
                </DesktopEditAndQuantity>
              </ItemButtonsDesktopWrap>
            </>
          )}

          {isMobile && (
            <>
              <div>
                <RemoveIconWrap
                  onClick={() => props.remove(props.item.lineItemId, props.item.code, props.item.quantity)}
                >
                  {!props.isMobile && props.labels.remove}
                  <RemoveIcon />
                </RemoveIconWrap>
              </div>
              {ItemData}
            </>
          )}
        </ContentWrap>
      </Base>

      {props.item.message && <Message>{props.item.message?.message}</Message>}
      {props.item.isThreeForTwo && props.incompleteOffer && <SpecialOffer offerName={props.incompleteOffer.name} offerLinkText={props.incompleteOffer.linkText} offerUrl={props.incompleteOffer.url} />}
    </styled.Div>
  );
};

export default styled(Item, {
  marginBottom: '10px',
});
