import { BorderLeftWidthProperty, BorderRadiusProperty } from 'csstype';
import React from 'react';
import Button, { Appearance as ButtonAppearance } from 'Shared/Button';
import { ErrorContainer, Label, LabelContainer, Wrapper } from 'Shared/Fields/Text';
import { ResponsiveSize, Text } from 'Shared/SharedComponents/atoms/Typography';
import { Down12x12, Up12x12 } from 'Shared/Icons/Chevron';
import * as style from 'Shared/Style';

import { styled, StyledProps } from '@glitz/react';
import { StyleOrStyleArray, WithThemeFunction } from '@glitz/type';
import { on } from '@polarnopyret/scope';

import { HeadText as ThemedHeadText } from './parts';
import { Close16x16 } from 'Shared/Icons/Close';
import { createPortal } from 'react-dom';

export const HeadText = ThemedHeadText;

const TRANSITION_DURATION = 400;

const ButtonStyled = styled(Button, {
  width: '100%',
  height: '100%',
});

const ButtonInner = styled.div({
  display: 'flex',
  alignItems: 'center',
  fontSize: '14px',
  fontWeight: 'normal',
  paddingTop: '8px',
  paddingRight: '8px',
  paddingBottom: '8px',
  paddingLeft: '8px',
});

const ToggleIconWrap = styled.span({
  display: 'flex',
  fontSize: '16px',
  marginLeft: '8px',
});

const getDropdownBox = (open: boolean, noOpenBorderBottom: boolean) =>
  styled.span({
    display: 'inline-block',
    width: '100%',
    height: '100%',
    position: 'relative',
    ['@media ' + style.mediaMinLarge]: {
      ...(open && {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        borderBottom: {
          color: noOpenBorderBottom ? 'transparent' : undefined,
        },
      }),
    },
  });

const DropdownContent = styled.div({
  position: 'absolute',
  backgroundColor: style.colors.monochrome.white,
  boxShadow: `0px 5px 10px -6px ${style.colors.monochrome.darkGrey}`,
  minWidth: '100%',
  zIndex: style.ZIndex.Dropdown,
  transformOrigin: 'top',
  left: 0,
  opacity: 0,
  transform: 'scaleY(0)',
});

const DropdownModal = styled.dialog({
  position: 'fixed',
  zIndex: 5,
  cursor: 'auto',
  inset: 'auto 0 16px 0',
  width: '90%',
  border: {
    xy: {
      width: 0,
    },
  },
  ['@media ' + style.mediaMinLarge]: {
    display: 'none',
  },
  '::backdrop': {
    background: 'rgba(0, 0, 0, 0.5)',
  },
  padding: {
    xy: 0,
  },
  backgroundColor: style.colors.monochrome.extraLightGrey,
  borderRadius: '8px',
});

const ModalClose = styled(Close16x16, {
  height: 16,
  width: 16,
  cursor: 'pointer',
  position: 'absolute',
  top: '12px',
  right: '12px',
});

export type PropType = StyledProps & {
  displayName?: string;
  closeOnClickInContent?: boolean;
  noBorder?: boolean;
  noOpenStyle?: boolean;
  noHoverStyle?: boolean;
  noTopMargin?: boolean;
  containContentInContainer?: boolean;
  growContentToContainer?: boolean;
  head?: React.ReactNode;
  noTransition?: boolean;
  noOpenBorderBottom?: boolean;
  borderWidth?: WithThemeFunction<
    BorderLeftWidthProperty<string | number> | BorderLeftWidthProperty<string | number>[]
  >;
  borderRadius?: WithThemeFunction<BorderRadiusProperty<string | number> | BorderRadiusProperty<string | number>[]>;
  displayNameStyle?: StyleOrStyleArray;
  useModal?: boolean;
  withPortal?: boolean;
};

type StateType = {
  open: boolean;
  dropdownPos: {
    top: number;
    left: number;
    width: number;
  };
};

const DropdownPortal = ({
  children,
  position,
  withPortal = false
}: {
  children: React.ReactNode;
  position: { top: number; left: number; width: number };
  withPortal: boolean;
}) => {
  if (withPortal) {
    return createPortal(
      <div
        style={{
          position: 'absolute',
          top: position.top,
          left: position.left,
          width: position.width,
          zIndex: 1000,
        }}
      >
        {children}
      </div>,
      document.body,
    );
  }

  return (
    <div
      style={{
        position: 'absolute',
        width: '100%',
        zIndex: 1000,
      }}
    >
      {children}
    </div>
  );
};

const DropdownShell = styled(
  class extends React.Component<PropType, StateType> {
    modalRef = React.createRef<HTMLDialogElement>();
    triggerRef = React.createRef<HTMLDivElement>();
    unbindRootClick: () => void;
    state = {
      open: false,
      dropdownPos: { top: 0, left: 0, width: 0 },
    };

    enableBodyClose() {
      const { open } = this.state;
      if (open) {
        this.unbindRootClick = on('click', () => this.setState({ open: false }));
      } else if (this.unbindRootClick) {
        this.unbindRootClick();
        delete this.unbindRootClick;
      }
    }
    componentDidMount() {
      this.enableBodyClose();
    }

    componentDidUpdate(prevProps: PropType, prevState: StateType) {
      if (this.state.open !== prevState.open) {
        this.enableBodyClose();
      }

      if (this.state.open) this.modalRef.current?.showModal();
      else this.modalRef.current?.close();
    }
    componentWillUnmount() {
      if (this.unbindRootClick) {
        this.unbindRootClick();
        delete this.unbindRootClick;
      }
    }

    onOpenClick = () => {
      if (this.triggerRef.current) {
        const rect = this.triggerRef.current.getBoundingClientRect();
        this.setState((prevState) => ({
          open: !prevState.open,
          dropdownPos: {
            top: rect.bottom + window.scrollY,
            left: rect.left + window.scrollX,
            width: rect.width,
          },
        }));
      } else {
        this.setState((prevState) => ({ open: !prevState.open }));
      }
    };

    onClickInContent = (e: React.MouseEvent<HTMLDivElement>) => {
      // We stop propagation because we close on clicks outside
      if (!this.props.closeOnClickInContent) {
        e.stopPropagation();
      }
    };

    render() {
      const {
        compose,
        noBorder,
        noOpenStyle,
        noHoverStyle,
        noTopMargin,
        noTransition,
        noOpenBorderBottom,
        borderWidth,
        borderRadius,
        containContentInContainer,
        growContentToContainer,
      } = this.props;
      const { open } = this.state;

      const DropdownBox = getDropdownBox(open, noOpenBorderBottom);

      return (
        <styled.Div css={compose()}>
          <DropdownBox
            ref={this.triggerRef}
            css={{
              ...(!noBorder && {
                border: {
                  xy: {
                    width: borderWidth ?? 'thin',
                    style: 'solid',
                    color: style.colors.monochrome.darkGrey,
                  },
                  radius: borderRadius,
                },
              }),
              ...(!noHoverStyle && {
                ':hover': {
                  backgroundColor: style.colors.monochrome.lightGrey,
                },
              }),
              ...(open &&
                !noOpenStyle && {
                backgroundColor: style.colors.monochrome.extraLightGrey,
              }),
            }}
          >
            <ButtonStyled inheritTextAlign appearance={[ButtonAppearance.Bare]} onClick={this.onOpenClick}>
              <ButtonInner>
                <HeadText css={this.props.displayNameStyle}>
                  {this.props.head ? this.props.head : this.props.displayName}
                </HeadText>
                <ToggleIconWrap>{this.state.open ? <Up12x12 /> : <Down12x12 />}</ToggleIconWrap>
              </ButtonInner>
            </ButtonStyled>
          </DropdownBox>

          {this.props.useModal ? (
            <DropdownModal
              ref={this.modalRef}
              onClick={() => this.props.closeOnClickInContent && this.setState({ open: false })}
            >
              <styled.Div
                css={{
                  position: 'relative',
                }}
              >
                <Text
                  fontSize={ResponsiveSize.D16_M16}
                  upperCase
                  css={{
                    width: '100%',
                    textAlign: 'center',
                    padding: {
                      y: '16px',
                    },
                  }}
                >
                  {this.props.displayName}
                </Text>

                <ModalClose onClick={() => this.setState({ open: false })} />
              </styled.Div>

              {this.props.children}
            </DropdownModal>
          ) : (
            <DropdownPortal position={this.state.dropdownPos} withPortal={this.props.withPortal}>
              <DropdownContent
                css={{
                  ...(!noTopMargin && {
                    marginTop: '8px',
                  }),
                  ...(containContentInContainer && {
                    right: 0,
                  }),
                  ...(growContentToContainer && {
                    minWidth: '100%',
                  }),
                  ...(!noTransition && {
                    ...style.transition({
                      property: ['transform', 'opacity'],
                      duration: `${TRANSITION_DURATION}ms`,
                      timingFunction: 'cubic-bezier(0.165, 0.840, 0.440, 1.000)',
                    }),
                  }),
                  ...(open && {
                    transform: 'scaleY(1)',
                    opacity: 1,
                  }),
                }}
                onClick={this.onClickInContent}
              >
                {this.props.children}
              </DropdownContent>
            </DropdownPortal>
          )}
        </styled.Div>
      );
    }
  },
);

export default styled(DropdownShell, {
  position: 'relative',
});

type DropdownPropType = PropType & {
  hideLabel?: boolean;
  label?: string;
  hideErrorMessage?: boolean;
  errorText?: string;
  isInvalid?: boolean;
  required?: boolean;
  allowOverflow?: boolean;
};

export const DropdownInput = styled(
  class extends React.Component<DropdownPropType> {
    render() {
      const {
        compose,
        label = '',
        errorText,
        hideLabel,
        hideErrorMessage,
        isInvalid,
        displayName,
        closeOnClickInContent,
        noBorder,
        noOpenStyle,
        noHoverStyle,
        noTopMargin,
        containContentInContainer,
        growContentToContainer,
        head,
        allowOverflow,
      } = this.props;

      const dropDownProps = {
        displayName,
        closeOnClickInContent,
        noBorder,
        noOpenStyle,
        noHoverStyle,
        noTopMargin,
        containContentInContainer,
        growContentToContainer,
        head,
        allowOverflow,
      };

      return (
        <Wrapper css={compose()}>
          {!hideLabel && (
            <LabelContainer>
              <Label>
                {label}
                {this.props.required && ' *'}
              </Label>
            </LabelContainer>
          )}
          <DropdownShell
            {...dropDownProps}
            css={compose({
              width: '100%',
              ...(allowOverflow && {
                position: 'relative',
              }),
            })}
          >
            {this.props.children}
          </DropdownShell>
          {!hideErrorMessage && (
            <ErrorContainer>{errorText && isInvalid && <Label invalid={isInvalid}>{errorText}</Label>}</ErrorContainer>
          )}
        </Wrapper>
      );
    }
  },
);
