import React, { useEffect, useState } from 'react';
import { styled } from '@glitz/react';
import connect from '../Shared/connect';
import * as style from '../Shared/Style';
import { ModalBase } from '../Shared/StyledComponents';
import { CLEAR_ADDED_TO_CART_MODAL, ClearAddedToCartModalAction } from '../Shared/uiReducer';
import { isCompact } from '../Shared/Viewport';
import { Close16x16 } from '../Shared/Icons/Close';
import Link from 'Shared/Generic/A';
import { ResponsiveSize, TextBlockSmall } from '../Shared/SharedComponents/atoms/Typography';
import { ANIMATION_DURATION } from 'SiteLayout/Header/DesktopHeader';
import CartItemViewModel from 'Cart/Models/CartItemViewModel.type';
import { AddedToCartModal } from 'Shared/State';
import { formatPrice } from 'Shared/number-format';
import { RatioPicture, Ratio as ImageRatio, Preset } from 'Shared/Image/Ratio';
import { checkoutPageUrl } from 'Shared/known-urls';

const margin = 8;

type RequiredPropType = {};

type ConnectedPropType = {
  addedToCartModal: AddedToCartModal | null;
  ClearAddedToCartModal: () => void;
  currentBreakpoint: number;
};

type PropType = RequiredPropType & ConnectedPropType & { headerHeight: number };

export const SecondaryRightmost = styled(ModalBase, {
  right: '8px',
  left: 'initial',
  ...style.transition({ property: ['transform', 'opacity'], duration: '300ms' }),
  height: 'auto',
  padding: { x: '12px', y: '12px' },
  gap: '8px',
  display: 'flex',
  maxWidth: 'calc(100vw - 16px)',
  boxShadow: '0px 0px 16px rgba(0, 0, 0, 0.16)',
  zIndex: 999,
});

const TopContentWrap = styled.div({
  display: 'flex',
  gap: '12px', width: '100%', flexWrap: 'wrap'
})

const TopContent = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  width: '100%',
})

const MessageContent = styled.div({
  display: 'flex',
  flexDirection: 'row',
  padding: { xy: '8px' },
  gap: '8px',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '8px',
  backgroundColor: style.hex2rgba(style.colors.brandColors.green, 0.3),
  width: '100%',
})

const Line = styled.hr({
  width: '100%',
  position: 'relative',
  backgroundColor: style.colors.monochrome.black,
  height: '2px',
  margin: { xy: 0 },
  border: { y: { width: 0 }, x: { width: 0 } },
  ...style.transition({ property: ['margin', 'background-color'], duration: `${ANIMATION_DURATION}ms` }),
})

const AddToCartModal = (props: PropType) => {
  const { currentBreakpoint, addedToCartModal, headerHeight, ClearAddedToCartModal } = props;
  const [retainedCartModal, setRetainedCartModal] = useState<AddedToCartModal | undefined>(addedToCartModal);
  const cartItem = retainedCartModal?.miniCart.cartViewModel.items.find(
    (item) => item.code === retainedCartModal?.code,
  );

  useEffect(() => {
    setRetainedCartModal(addedToCartModal ?? retainedCartModal);
  }, [addedToCartModal]);

  useEffect(() => {
    if (addedToCartModal) {
      setTimeout(() => {
        ClearAddedToCartModal();
      }, 5000);
    }
  }, [addedToCartModal]);

  const isMobile = isCompact(currentBreakpoint);
  return (
    <SecondaryRightmost
      css={{
        top: headerHeight + margin,
        transform: !!!addedToCartModal
          ? 'translateY(0px) translateX(calc(200% + 8px))'
          : 'translateY(0px) translateX(0%)',
        width: isMobile ? '334px' : '442px',
      }}
      onTransitionEnd={() => {
        if (!addedToCartModal) {
          setRetainedCartModal(undefined);
        }
      }}
    >
      {retainedCartModal && (
        <TopContentWrap>
          <TopContent>
            <TextBlockSmall>{retainedCartModal?.miniCart?.labels?.addedToCart}</TextBlockSmall>
            <Close16x16 css={{ cursor: 'pointer' }} onClick={() => ClearAddedToCartModal()} />
          </TopContent>
          <Line />
        </TopContentWrap>
      )}
      {cartItem && <CartItem cartItem={cartItem} sizeLabel={retainedCartModal?.miniCart?.labels?.size} />}

      {retainedCartModal?.message && retainedCartModal.message.length !== 0 && (
        <MessageContent>
          <TextBlockSmall
            fontSize={ResponsiveSize.D12_M12}
            css={{
              lineHeight: '120%', //Hack, works fine here
            }}
          >
            {retainedCartModal.message}
          </TextBlockSmall>
        </MessageContent>
      )}
    </SecondaryRightmost>
  );
};

const ItemWrap = styled(Link, {
  display: 'flex',
  alignItems: 'flex-start',
  padding: { xy: '8px' },
  gap: '8px',
  borderRadius: '8px',
  backgroundColor: style.colors.monochrome.extraLightGrey,
  width: '100%',
  color: style.colors.monochrome.black,
  textDecoration: 'none',
})

const ItemImageWrap = styled.div({
  width: '78px'
})

const ItemContentWRap = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  gap: '8px',
  height: '100%',
})

const ItemPrice = styled.div({
  display: 'flex',
  alignItems: 'center',
  gap: '4px',
})


export default connect(
  (state) => ({
    addedToCartModal: state.UI.addedToCartModal,
    currentBreakpoint: state.currentBreakpoint,
    cart: state.appShellData.cart,
  }),
  (dispatch) => ({
    ClearAddedToCartModal: () =>
      dispatch({
        type: CLEAR_ADDED_TO_CART_MODAL,
      }) as ClearAddedToCartModalAction,
  }),
)(AddToCartModal);

export const CartItem = connect((state) => ({
  culture: state.appShellData.culture,
  currency: state.appShellData.market.currency,
}))(
  ({
    displayedInCart,
    cartItem,
    culture,
    currency,
    sizeLabel,
  }: {
    displayedInCart?: {
      itemCount: number;
      removeLabel: string;
      remove: () => void;
      incrementEnabled: boolean;
      incrementCount: () => void;
      decrementEnabled: boolean;
      decrementCount: () => void;
    };
    cartItem: CartItemViewModel;
    culture: string;
    currency: string;
    sizeLabel: string;
  }) => {
    //TODO: Add controls for manipulating quantity etc, as neccessary when displayed in cart

    return (
      <ItemWrap
        to={checkoutPageUrl()}
      >
        <ItemImageWrap>
          <RatioPicture
            src={cartItem.imageUrl}
            alt={cartItem.displayName}
            ratio={ImageRatio.ThreeToFour}
            preset={Preset.ItemThumbLarge}
          />
        </ItemImageWrap>
        <ItemContentWRap>
          <TextBlockSmall>{cartItem.displayName}</TextBlockSmall>
          <TextBlockSmall css={{ color: style.colors.monochrome.a11ygrey }}>
            {cartItem.color}, {sizeLabel}: {cartItem.sizeAndAge}
          </TextBlockSmall>
          <ItemPrice>
            <TextBlockSmall>{formatPrice(cartItem.placedPrice, currency, culture)}</TextBlockSmall>
          </ItemPrice>
        </ItemContentWRap>
      </ItemWrap>
    );
  },
);