import React from 'react';
import { translate } from '@polarnopyret/scope';
import { styled, StyledProps } from '@glitz/react';
import { Plus12x12 } from 'Shared/Icons/Plus';
import { Minus12x12 } from 'Shared/Icons/Minus';
import FeedbackButton, { ButtonTheme } from 'Shared/Button/Feedback';
import { BareInput } from 'Shared/Fields/Text';
import { isValidQuantity } from '../../Cart/action-creators';
import * as style from 'Shared/Style';

function parse(int: string) {
  return parseInt(int, 10);
}

const QuantityButton = styled(FeedbackButton, {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const QuantityButtonLeft = styled(QuantityButton, {
  paddingLeft: '16px',
  paddingRight: '0'
})

const QuantityButtonRight = styled(QuantityButton, {
  paddingRight: '16px',
  paddingLeft: '0px'
})

const QuantityField = styled.span({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexGrow: 1,
});

const StyledText = styled(BareInput, {
  display: 'block',
  width: '4.182ch',
  textAlign: 'center',
  lineHeight: 1,
  height: '100%',
  paddingTop: 0,
  paddingRight: 0,
  paddingBottom: 0,
  paddingLeft: 0,
});

const Plus = styled(Plus12x12, {
  color: style.colors.monochrome.black,
})

const Wrap = styled.span({
  borderRadius: '40px',
  height: '40px',
  border: {
    xy: { color: style.colors.monochrome.black, width: 2, style: 'solid' },
  },
})

type PropType = StyledProps & {
  value: number;
  update: (quantity: number) => Promise<void>;
  maxQuantity?: number;
  className?: string;
};

type StateType = {
  proxyValue?: any;
  invalid?: boolean;
};

class Quantity extends React.Component<PropType, StateType> {
  isFocused = false;
  constructor(props: PropType) {
    super(props);
    this.state = {
      proxyValue: props.value,
      invalid: false,
    };
  }
  componentWillReceiveProps(nextProps: PropType) {
    if (this.isFocused) {
      return;
    }

    if (this.state.proxyValue !== nextProps.value) {
      this.setState({
        proxyValue: nextProps.value,
      });
    }
  }
  async change(value: string | number) {
    const parsedValue = typeof value === 'number' ? value : parse(value);

    if (parsedValue <= 0) {
      this.setState({
        proxyValue: 0,
      });
      await new Promise(r => setTimeout(r, 600));
    } else {
      this.setState({
        proxyValue: parsedValue || '',
      });
    }

    return this.update(parsedValue);
  }
  reduce() {
    const quantity = parse(this.state.proxyValue) - 1;
    if (isValidQuantity(quantity)) {
      return this.change(quantity);
    }
    return Promise.reject(null);
  }
  add() {
    const quantity = (parse(this.state.proxyValue) || 0) + 1;
    if (isValidQuantity(quantity, this.props.maxQuantity)) {
      return this.change(quantity);
    }
    return Promise.reject(null);
  }
  update(quantity: number) {
    const previousValue = this.state.proxyValue;
    return this.props
      .update(quantity)
      .then(() => {
        if (this.state.proxyValue === this.props.value && this.state.invalid) {
          this.setState({
            invalid: false,
          });
        }
      })
      .catch(() => {
        if (!this.isFocused) {
          // Reset hard if field is not focused
          this.setState({
            proxyValue: previousValue,
            invalid: true,
          });
        } else if (!this.state.invalid) {
          this.setState({
            invalid: true,
          });
        }
      });
  }
  verify(e: React.SyntheticEvent<{}>) {
    if (this.state.invalid) {
      this.setState({
        proxyValue: this.props.value,
        invalid: false,
      });
    }
  }
  render() {
    return (
      <Wrap css={this.props.compose()}>
        <QuantityButtonLeft
          title={translate('/site/shared/decreasequantity')}
          theme={ButtonTheme.None}
          onClick={(e) => {
            e.preventDefault();
            return this.reduce();
          }}
          autoHeight
          type="button"
          feedBackSize={12}
          disabled={this.state.proxyValue.toString() === "1"}
        >
          <Minus12x12 css={{ color: this.state.proxyValue.toString() === "1" ? 'rgb(0, 0 ,0, 0.3)' : style.colors.monochrome.black }} />
        </QuantityButtonLeft>
        <QuantityField>
          <StyledText
            type="number"
            size={3}
            value={this.state.proxyValue}
            onChange={e => this.change((e.target as HTMLInputElement).value)}
            onFocus={() => {
              this.isFocused = true;
            }}
            onBlur={e => {
              this.isFocused = false;
              this.verify(e);
            }}
          />
        </QuantityField>
        <QuantityButtonRight
          title={translate('/site/shared/increasequantity')}
          theme={ButtonTheme.None}
          type="button"
          autoHeight
          onClick={(e) => {
            e.preventDefault();
            return this.add();
          }}
          feedBackSize={12}
        >
          <Plus />
        </QuantityButtonRight>
      </Wrap>
    );
  }
}

export default styled(Quantity, {
  display: 'flex',
});
