/* import React from 'react';
import { withTheme } from 'Shared/Themes';
import { styled, StyledComponent, StyledProps } from '@glitz/react';
 */
/* export const HeadText = withTheme(({ theme, ...restProps }) => (
  <theme.dropdownParts.HeadText>{restProps.children}</theme.dropdownParts.HeadText>
));

type PropType = React.HTMLAttributes<HTMLLIElement> & StyledProps;

export const OptionLi = styled(
  withTheme<PropType>(({ theme, setTheme, themeName, compose, ...restProps }) => (
    <theme.dropdownParts.OptionLi {...restProps} />
  )),
); */
import { HeadText as SharedHeadText, OptionLi as SharedOptionLi } from './shared';

export const HeadText = SharedHeadText;
export const OptionLi = SharedOptionLi;