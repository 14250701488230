import React from 'react';
import { styled } from '@glitz/react';
import connect from 'Shared/connect';
import ProductCartItem from '../../Product/ProductCartItem'
import MiniCartViewModel from 'Cart/Models/MiniCartViewModel.type';
import { updateCartItemQuantity, removeCartItem } from '../action-creators';
const Base = styled.div({
});

type RequiredPropType = {
  miniCart: MiniCartViewModel;
  isMobile: boolean;
};

type PropType = RequiredPropType & {
  updateCartItemQuantity: (lineId: number, code: string, quantity: number) => Promise<void>;
  removeCartItem: (lineId: number, code: string) => Promise<void>;
  close?: () => void;
};

const FullCart = (props: PropType) => {
  return (
    <Base>
      {props.miniCart.cartViewModel.items.map(item => (
        <ProductCartItem
          key={item.extId}
          labels={props.miniCart.labels}
          isMobile={props.isMobile}
          item={item}
          incompleteOffer={props.miniCart.cartViewModel.incompleteOffer}
          close={props.close}
          update={props.updateCartItemQuantity}
          remove={props.removeCartItem}
        />
      ))}
    </Base>
  );
};

export default connect(
  state => ({}),
  dispatch => ({
    updateCartItemQuantity(lineId: number, code: string, quantity: number) {
      return dispatch(updateCartItemQuantity(lineId, code, quantity, 'Varukorgsmodul'));
    },
    removeCartItem(lineId: number, code: string) {
      return dispatch(removeCartItem(lineId, code, 'Varukorgsmodul'));
    },
  }),
)(FullCart);
